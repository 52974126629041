
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import axios from '@/http'
interface IAddForm {
	name: string
	gender: string
	height: string
	chest: string
	waist: string
	hips: string
	[key: string]: string
}
const genders = [
	{
		value: '0',
		label: 'Женщина'
	},
	{
		value: '1',
		label: 'Мужчина'
	},
	{
		value: '2',
		label: 'Девочка'
	},
	{
		value: '3',
		label: 'Мальчик'
	}
]
const initialAddForm: IAddForm = {
	name: '',
	gender: '',
	height: '',
	chest: '',
	waist: '',
	hips: ''
}
export default defineComponent({
	setup() {
		const activePhotomodels = ref()
		const archivePhotomodels = ref()

		onMounted(async () => {
			await updatePhotomodels()
		})

		const addForm = reactive<IAddForm>({ ...initialAddForm })
		const errorMsg = ref<string>('')
		const numberField = ['height', 'chest', 'waist', 'hips', 'gender']

		async function updatePhotomodels() {
			const [active, archive] = await Promise.all([
				axios.get('/internal/get-photomodels-active'),
				axios.get('/internal/get-photomodels-archive')
			])
			activePhotomodels.value = active.data
			archivePhotomodels.value = archive.data
		}

		async function setArchivePhotomodel(id: number) {
			await axios.get('/internal/set-photomodels-archive/' + id)
			await updatePhotomodels()
		}

		async function setActivePhotomodel(id: number) {
			await axios.get('/internal/set-photomodels-active/' + id)
			await updatePhotomodels()
		}

		function getGenderByNumber(genderNum: string) {
			const gender = genders.find(g => g.value == genderNum)
			return gender?.label
		}

		function checkForm() {
			errorMsg.value = ''
			const numberFieldTranslate = ['Рост (см)', 'Грудь (см)', 'Талия (см)', 'Бедра (см)', 'Пол']
			for (const key in addForm) {
				const value = addForm[key]
				if (key === 'name' && value === '') {
					errorMsg.value += 'Поле Имя не может быть пустым <br>'
				}
				if (numberField.includes(key)) {
					const idx = numberField.findIndex(field => field === key)
					const translate = numberFieldTranslate[idx]
					if (!value) errorMsg.value += `Поле ${translate} не может быть пустым <br>`
					else if (isNaN(+value)) errorMsg.value += `Поле ${translate} должен быть числом <br>`
				}
			}
			// если поле ошибки валидации пустое
			// то все ок
			return !errorMsg.value
		}
		function transformForm() {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const result: { [key: string]: any } = {}
			for (const key in addForm) {
				let value: string | number = addForm[key]
				if (numberField.includes(key)) {
					value = +value
				}
				result[key] = value
			}
			return result
		}
		async function createPhotomodel() {
			try {
				const isValid = checkForm()
				if (!isValid) return
				const form = transformForm()
				await axios.post('/internal/create-photomodel', form)
				Object.assign(addForm, initialAddForm)
				ElMessage.success('Фотомодель успешно добавлено')
				await updatePhotomodels()
			} catch (e) {
				ElMessage.error('Не удалось добавить фотомодель')
			}
		}
		return {
			activePhotomodels,
			archivePhotomodels,
			addForm,
			errorMsg,
			setArchivePhotomodel,
			setActivePhotomodel,
			getGenderByNumber,
			createPhotomodel,
			genders
		}
	}
})
